import React from 'react';
import './App.css';
import Comingsoon from './components/Comingsoon/Comingsoon';
import {BrowserRouter as Router, Switch , Route} from 'react-router-dom';

function App() {

  return(

    <Router>
    <div>
      <Comingsoon />
    </div>

  </Router>

  );

}

export default App;
